.registration {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow: hidden;

  .mat-step-icon {
    border: 1px solid map-get($map: $primary-pallete, $key: 500);
    background-color: transparent;
    width: 16px;
    height: 16px;
    margin-top: 16px;

    &-content {
      display: none !important;
    }
  }

  .mat-step-text-label {
    font-size: 14px;
    line-height: 18px;
    font-weight: normal;
    letter-spacing: -0.02em;
    color: #bdbdbd;
  }

  .mat-step-label-selected {
    .mat-step-text-label {
      color: map-get($map: $primary-pallete, $key: 500);
    }
  }

  .mat-step-icon.mat-step-icon-state-edit {
    background-color: map-get($map: $primary-pallete, $key: 500) !important;
    position: relative;
  }

  .mat-step-icon.mat-step-icon-state-edit::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url("data:image/svg+xml;utf8,<svg width='8' height='6' viewBox='0 0 8 6' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M2.85714 6L0 3.12092L0.805714 2.30902L2.85714 4.37044L7.19429 0L8 0.817658L2.85714 6Z' fill='white'/></svg>");
    background-repeat: no-repeat;
    background-position: center;
  }

  .mat-step-header.cdk-program-focused,
  .mat-step-header:hover {
    background-color: transparent;
  }

  .mat-horizontal-content-container {
    padding: 0;
  }

  .mat-stepper-label-position-bottom .mat-horizontal-stepper-header {
    padding: 12px 5px;
  }

  .mat-stepper-horizontal {
    width: 100%;
    max-height: 100% !important;
    display: flex !important;
    flex-direction: column !important;

    .mat-horizontal-content-container {
      max-height: 100%;
      display: flex;
      flex-direction: column;

      .mat-horizontal-stepper-content {
        max-height: 100%;
        width: 100%;
        overflow: hidden;
        display: flex;
        flex-direction: column;
      }
    }
  }

  .auth__actions {
    margin-top: 16px;
  }

  &__radio-button {
    border-radius: 3px !important;
    border: 1px solid #e0e0e0 !important;
    transition-property: border-color, box-shadow;
    transition-duration: 0.2s;
    transition-timing-function: linear;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: -0.24px;
    padding-top: 4px !important;
    padding-bottom: 4px !important;
    text-align: left!important;
    //width: calc(50% - 6px);
    width: 100%;
    @media screen and (max-width: 450px) {
      padding-left: 12px !important;
      padding-right: 12px !important;
    }

    &_active {
      box-shadow: 0px 3px 10px -2px rgba(0, 0, 0, 0.2) !important;
      border-color: transparent !important;
    }

    &s {
      display: flex;
      flex-direction: column;
      width: 100%;
      justify-content: space-between;
      padding: 4px;
    }
  }

  &__radio-button + &__radio-button {
    //margin-left: 12px !important;
    margin-top: 10px;
  }

  &__step {
    &-container {
      margin-top: 8px;
      margin-bottom: 16px;
      overflow-y: auto;
    }
  }
}
