$custom-dark-primary-text: #333333;
$custom-light-primary-text: #ffffff;


$dark-primary-text: rgba(black, 0.87);
$dark-secondary-text: rgba(black, 0.54);
$dark-disabled-text: rgba(black, 0.38);
$dark-dividers: rgba(black, 0.12);
$dark-focused: rgba(black, 0.12);
$light-primary-text: white;
$light-secondary-text: rgba(white, 0.7);
$light-disabled-text: rgba(white, 0.5);
$light-dividers: rgba(white, 0.12);
$light-focused: rgba(white, 0.12);


$primary-pallete: (


  50: #e8eaf6,
  100: #c5cae9,
  200: #9fa8da,
  300: #7986cb,
  400: #5c6bc0,
  500: #3f51b5,
  600: #3949ab,
  700: #303f9f,
  800: #283593,
  900: #1a237e,
  A100: #8c9eff,
  A200: #536dfe,
  A400: #3d5afe,
  A700: #304ffe,


  //50: #ecf5de,
  //100: #d3ebaf,
  //200: #abd769,
  //300: #97cf43,
  //400: #83c60d,
  //500: #73b600,
  //600: #6eae00,
  //700: #67a400,
  //800: #528f00,
  //900: #407000,
  //A100: #d3ebaf,
  //A200: #97cf43,
  //A400: #73b600,
  //A700: #528f00,

//  900: #00106F,
//800: #0F2397,
//700: #2135A6,
//600: #3749B6,
//500: #001489,
//400: #6E7EDD,
//300: #92A0F3,
//200: #B2BEFF,
//100: #CCD5FF,
//50: #E5EAFF,
//
//  A100: #CCD5FF,
//  A200: #92A0F3,
//  A400: #5062C9,
//  A700: #0F2397,

  //900: #001489,
  //800: #0F2397,
  //700: #2135A6,
  //600: #3749B6,
  //500: #5062C9,
  //400: #6E7EDD,
  //300: #92A0F3,
  //200: #B2BEFF,
  //100: #CCD5FF,
  //50: #E5EAFF,
  //
  //A100: #CCD5FF,
  //A200: #92A0F3,
  //A400: #5062C9,
  //A700: #0F2397,

  //900: #0F2397,
  //800: #2135A6,
  //700: #3749B6,
  //600: #5062C9,
  //500: #6E7EDD,
  //400: #92A0F3,
  //300: #B2BEFF,
  //200: #CCD5FF,
  //100: #E5EAFF,
  //50: #F2F5FF,
  //
  //
  //A100: #E5EAFF,
  //A200: #B2BEFF,
  //A400: #6E7EDD,
  //A700: #2135A6,

  //900: #392C7C,
  //800: #4B3AA1,
  //700: #5E4AC3,
  //600: #745CDF,
  //500: #8C71F4,
  //400: #A487FF,
  //300: #BBA0FF,
  //200: #D1BAFF,
  //100: #E5D5FF,
  //50: #F7F1FF,
  //
  //A100: #E5D5FF,
  //A200: #BBA0FF,
  //A400: #8C71F4,
  //A700: #4B3AA1,

  contrast: (
    50: $custom-dark-primary-text,
    100: $custom-dark-primary-text,
    200: $custom-dark-primary-text,
    300: $custom-dark-primary-text,
    400: $custom-light-primary-text,
    500: $custom-light-primary-text,
    600: $custom-light-primary-text,
    700: $custom-light-primary-text,
    800: $custom-light-primary-text,
    900: $custom-light-primary-text,
    A100: $custom-dark-primary-text,
    A200: $custom-dark-primary-text,
    A400: $custom-dark-primary-text,
    A700: $custom-dark-primary-text,
  ),
);

$accent-pallete: (
  50: #eee5f9,
  100: #d4c0ef,
  200: #b795e5,
  300: #9a69db,
  400: #8345d3,
  500: #6b1ccb,
  600: #6117c5,
  700: #520abc,
  800: #4300b6,
  900: #2500ac,
  A100: #d4c0ef,
  A200: #9a69db,
  A400: #6b1ccb,
  A700: #4300b6,
  contrast: (
    50: $custom-dark-primary-text,
    100: $custom-dark-primary-text,
    200: $custom-dark-primary-text,
    300: $custom-light-primary-text,
    400: $custom-light-primary-text,
    500: $custom-light-primary-text,
    600: $custom-light-primary-text,
    700: $custom-light-primary-text,
    800: $custom-light-primary-text,
    900: $custom-light-primary-text,
    A100: $custom-dark-primary-text,
    A200: $custom-light-primary-text,
    A400: $custom-light-primary-text,
    A700: $custom-light-primary-text,
  ),
);

$warn-pallete: (
  50: #ffeaed,
  100: #ffc9cf,
  200: #f59393,
  300: #ec6769,
  400: #f63f43,
  500: #fb2424,
  600: #ec1225,
  700: #da001f,
  800: #ce0017,
  900: #c00007,
  A100: #ffc9cf,
  A200: #ec6769,
  A400: #fb2424,
  A700: #ce0017,
  contrast: (
    50: $custom-dark-primary-text,
    100: $custom-dark-primary-text,
    200: $custom-dark-primary-text,
    300: $custom-dark-primary-text,
    400: $custom-dark-primary-text,
    500: $custom-light-primary-text,
    600: $custom-light-primary-text,
    700: $custom-light-primary-text,
    800: $custom-light-primary-text,
    900: $custom-light-primary-text,
    A100: $custom-dark-primary-text,
    A200: $custom-dark-primary-text,
    A400: $custom-light-primary-text,
    A700: $custom-light-primary-text,
  ),
);

$max-width: 1683px;
$header-height: 66px;
