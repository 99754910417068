.lot {
  display: block;
  padding: 16px;
  background-color: white;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.24);
  cursor: pointer;
  max-width: 978px;

  &-technical-item {
    display: flex;
    align-items: center;
    padding: 6.5px 16px;
    background-color: #a8a8a81a;
    border-radius: 3px;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.02em;
    color: $custom-dark-primary-text;
    white-space: nowrap;

    &__icon.mat-icon {
      margin-right: 10px;
      height: 20px;
      width: 20px;
      line-height: 20px;
      font-size: 20px;
    }

    &_status {
      &-waiting {
        background: rgba(242, 201, 76, 0.2);
        color: #dcab12;
      }

      &-actual {
        // background: #528f001a;
        background:map-get($map: $primary-pallete, $key: 50);
        color: map-get($map: $primary-pallete, $key: 800);
      }

      &-atcomission {
        background: rgba(107, 28, 203, 0.1);
        color: #6b1ccb;
      }

      &-closed {
        background: rgba(251, 36, 36, 0.1);
        color: #fb2424;
      }
    }
  }

  &__menu {
    display: flex;
    align-items: center;
    margin-left: auto;
    height: 40px;

    @media screen and (max-width: 1000px) {
      width: 100%;
      flex-shrink: 0;
      margin-left: 6px;
      margin-top: 16px;
    }

    &-item {
      border-radius: 3px;

      &_actions {
        // background-color: rgba(82, 143, 0, 0.1);
        background-color: map-get($map: $primary-pallete, $key: 50);

        .atp-table-actions-menu__open-button_single {
          opacity: 1;
        }

        .mat-icon {
          color: map-get($map: $primary-pallete, $key: 800);
        }
      }
    }

    &-item + &-item {
      margin-left: 3px;
    }
  }

  &__title {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: $custom-dark-primary-text;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -moz-box;
    -moz-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    line-clamp: 3;
    box-orient: vertical;
    max-height: 60px;
  }

  &__number {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.02em;
    color: map-get($map: $primary-pallete, $key: 800);
    margin-bottom: 6px;
  }

  &__other-item {
    margin-top: 6px;
    margin-left: 48px;

    &_type {
      width: 48px;
      white-space: nowrap;
    }

    &_organizations {
      .lot__other-item-text {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -moz-box;
        -moz-box-orient: vertical;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        line-clamp: 2;
        box-orient: vertical;
        max-height: 36px;
      }
    }

    &-title {
      font-size: 12px;
      line-height: 18px;
      letter-spacing: -0.02em;
      color: #bdbdbd;
      margin-bottom: 4px;
    }

    &-text {
      font-size: 14px;
      line-height: 18px;
      color: $custom-dark-primary-text;

      &_bold {
        font-weight: 600;
      }

      &_green {
        color: map-get($map: $primary-pallete, $key: 800);
      }
    }
  }

  &__info {
    display: flex;
    align-items: center;

    &_technical {
      min-height: 40px;
      margin-bottom: 16px;
      margin-left: -6px;
      margin-top: -6px;
      position: relative;

      @media screen and (max-width: 1000px) {
        flex-wrap: wrap;
      }

      .lot-technical-item {
        margin-left: 6px;
        margin-top: 6px;
      }
    }

    &_main {
      flex-direction: column;
      align-items: flex-start;
    }

    &_other {
      flex-wrap: wrap;
      margin-left: -48px;
    }
  }

  &-time {
    display: flex;
    align-items: center;
    height: 40px;
    padding: 0 20px;
    border-radius: 3px;
    color: white;
    background-color: map-get($primary-pallete, 500);

    &__value:nth-child(odd) {
      margin-left: 10px;
    }

    &__value:nth-child(even) {
      margin-left: 4px;
    }
  }
}
