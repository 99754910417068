@import "./step-control/step-control.component";

.add-edit-lot-stage {
  display: block;
  height: 100%;
}

.atp-dialog-container {
  .add-edit-offer__title-item {
    display: block;

    h3 {
      @media screen and (max-width: 400px) {
        font-size: 26px;
        line-height: 30px;
      }

      @media screen and (max-width: 320px) {
        font-size: 24px;
        line-height: 28px;
      }
    }
  }

  .add-edit-offer {
    &__title {
      padding-top: 0;
    }
  }
}

.add-edit-offer {
  display: block;
  position: relative;
  height: 100%;

  .atp-dynamic-form__content {
    z-index: 100;

    //изменил
    height: 0;
    flex: 1 1 auto;
  }

  &__popup {
    &-content {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: calc(100% - 6px);
      padding: 0 8px;
      box-shadow: 0px 2px 4px rgb(0 0 0 / 24%);
      background-color: white;
      border-radius: 3px;
      margin: 3px 3px 6px;

      &-actions {
        position: fixed;
        bottom: 0;
        right: 32px;
        display: flex;
        align-items: center;
        padding: 11px 0;

        @media screen and (max-width: 750px) {
          position: initial;
          padding: 0px;
        }

        @media screen and (max-width: 450px) {
          flex-direction: column;
        }

        .add-edit-lot-table__popup-content-actions-button + .add-edit-lot-table__popup-content-actions-button {
          @media screen and (max-width: 750px) {
            margin-left: 16px;
          }
        }

        .add-edit-lot-table__popup-content-actions-button {
          @media screen and (max-width: 750px) {
            padding-left: 4px !important;
            padding-right: 4px !important;
            line-height: 30px !important;
          }

          @media screen and (max-width: 320px) {
            line-height: 24px !important;
          }
        }

        // &-button {
        //   color: map-get($map: $primary-pallete, $key: 800) !important;

        // }
      }
    }
  }

  &__ag-grid {
    &-container {
      display: flex;
      flex-direction: column;
      width: calc(100% - 6px);
      height: calc(100% - 9px);
      padding: 0 8px;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.24);
      background-color: white;
      border-radius: 3px;
      margin: 3px 3px 6px;
    }

    &-content {
      height: 100%;
      flex-grow: 1;
    }

    &-footer {
      width: 100%;
      height: 120px;
      flex-grow: 0;
    }
  }

  &__your-own-conditions {
    &-title:first-child {
      margin-top: 0;
    }

    &-title {
      font-weight: 600;
      font-size: 22px;
      line-height: 28px;
      letter-spacing: -0.24px;
      color: $custom-dark-primary-text;
      margin-top: 32px;
      margin-bottom: 24px;

      @media screen and (max-width: 600px) {
        margin-top: 16px;
        margin-bottom: 16px;
      }
    }

    &-group {
      padding-bottom: 32px !important;

      textarea {
        height: 200px !important;
      }
    }
  }

  &__results {
    &-row {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 8px 0;
      border-top: 1px solid #d3d9de80;

      &-title {
        color: #bdbdbd;
        font-size: 14px;
        line-height: 16px;
        padding-right: 16px;
      }

      &-value {
        color: $custom-dark-primary-text;
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        padding-left: 4px;
      }
    }

    &-row:last-child {
      padding-bottom: 16px;
    }
  }

  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-top: 24px;
    padding-bottom: 24px;

    @media screen and (max-width: 750px) {
      flex-direction: column;
      align-items: center;
      padding-bottom: 12px;
      padding-top: 12px;
    }

    @media screen and (max-width: 400px) {
      padding-top: 8px;
      padding-bottom: 8px;
    }

    @media screen and (max-width: 320px) {
      padding-top: 0;
      padding-bottom: 0;
    }

    &-item {
      display: none;
    }

    .lot-time {
      margin-left: auto;

      @media screen and (max-width: 750px) {
        margin-left: 0;
        margin-top: 8px;
      }

      @media screen and (max-width: 320px) {
        height: 30px;
      }
    }
  }
}
