.lots {
  position: relative;
  display: block;
  max-width: 978px;

  &__actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-shrink: 0;

    @media screen and (max-width: 450px) {
      margin-top: 16px;
    }

    &-panel {
      display: flex;
      align-items: center;
      padding: 32px 0;

      @media screen and (max-width: 1000px) {
        padding: 16px 0;
      }

      @media screen and (max-width: 450px) {
        flex-direction: column;
      }
    }
  }

  &__filters-panel {
    max-width: none;
    width: 100%;
  }

  &__filters-panel + &__actions {
    margin-left: 8px;

    @media screen and (max-width: 450px) {
      margin-left: 0;
    }
  }

  &__global-filters.mat-form-field-appearance-outline {
    // background: rgba(82, 143, 0, 0.1);
    background: map-get($map: $primary-pallete, $key: 50);
    width: 145px;
    margin-left: 26px;

    @media screen and (max-width: 600px) {
      margin-left: 0;
    }

    .mat-form-field-wrapper {
      padding-bottom: 0;
      margin: 0;
    }

    .mat-select-arrow-wrapper {
      opacity: 0;
    }

    .mat-icon {
      width: 20px;
      margin-left: -16px;
    }
  }

  &__top-panel {
    position: relative;
    display: flex;
    align-items: center;
    padding-bottom: 16px;
    border-bottom: 1px solid #cecece;

    @media screen and (max-width: 1000px) {
      flex-wrap: wrap;
      justify-content: center;
      padding-bottom: 8px;
    }

    .mat-paginator-container {
      @media screen and (max-width: 450px) {
        justify-content: center;
      }
    }
  }

  &__title {
    &-count {
      margin-left: 18px;
      background-color: map-get($map: $primary-pallete, $key: 50);
      // background-color: rgba(82, 143, 0, 0.1);
      border-radius: 3px;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: map-get($map: $primary-pallete, $key: 800);
      padding: 7px 12px;
    }

    &-container {
      display: flex;
      align-items: center;

      @media screen and (max-width: 1000px) {
        justify-content: center;
        width: 100%;
        flex-shrink: 0;
        margin-bottom: 8px;
      }
    }
  }

  &__paginator {
    background-color: transparent;
    margin-left: auto;

    @media screen and (max-width: 1000px) {
      margin-left: 0;
    }

    .mat-paginator-range-label {
      display: none;
    }
  }

  &__list {
    margin: 30px 0;

    @media screen and (max-width: 1000px) {
      margin: 16px 0;
    }

    .lot + .lot {
      margin-top: 16px;
    }

    &-plug {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 145px;

      small {
        color: #bdbdbd;
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
}

.atp-dialog-container {
  .lot-popup {
    padding-top: 0;

    .atp-dynamic-form {
      &__actions {
        //margin: auto -32px 0;
        //width: calc(100% + 64px);
      }
    }
  }
}

.lot-popup {
  width: 100%;
  height: 100%;
  // padding-top: 32px;

  .atp-dynamic-form {
    &__container {
      @include changeGroups("atp-dynamic-form__content");
    }

    &__content {
      //изменил
      overflow: hidden;

      margin: 0;
      width: 100%;
      height: 100%;
      padding: 0;
      background-color: transparent;
      box-shadow: none;
    }

    &__actions {
      margin-left: 0;
      margin-right: 0;
      width: 100%;
    }
  }

  @include changeGroups("atp-dynamic-form__tab-item-content");

  .atp-dynamic-form__tab-item-content {
    margin-bottom: 6px;
  }
}

.add-edit-lot {
  display: block;
  position: relative;

  .atp-dynamic-form {
    &__content {
      position: relative;
      z-index: 1;
    }

    &__actions {
      position: relative;
      z-index: 0;
    }
  }
}

.lots-notification {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 0 4px 16px;
  border-radius: 5px;
  overflow: hidden;
  flex-shrink: 0;

  &__text {
    font-weight: 600;
    font-size: 15px;
    line-height: 19px;
  }

  &__button {
    opacity: 0.5;
  }

  &_success {
    color: map-get($map: $primary-pallete, $key: 800);
    background-color: rgba(map-get($map: $primary-pallete, $key: 800), 0.2);
  }

  &_warn {
    background-color: rgba(#f2c94c, 0.3);
    color: #dcab12;
  }

  &_normal {
    background-color: rgba(#e0e0e0, 0.5);
    color: #333333;
  }
}

@import "./lot/lot.component";
@import "./detail-lot/detail-lot.component";
@import "./lot-templates/lot-templates.component";
@import "./add-edit-lot-custom-offer-columns/add-edit-lot-custom-offer-columns.component";
@import "./lot-templates/lot-templates.component";
@import "./add-edit-lot-table/add-edit-lot-table.component";
@import "./ag-grid-components/ag-grid-components";
@import "./add-edit-lot-table-choise-product/add-edit-lot-table-choise-product.component";
@import "./management-offers/management-offers.component";
@import "./stage-users-checkboxes/stage-users-checkboxes.component";
@import "./add-edit-offer/add-edit-offer.component";

.atp-preloader-container_lots {
  position: fixed;
}
