.atp-filters-panel-presets {
  display: flex;
  flex-direction: column;
  width: 29%;
  padding-right: 12px;
  flex-shrink: 0;

  &__edit-mode-button {
    width: 20px !important;
    height: 20px !important;

    &-icon {
      font-size: 14px !important;
      line-height: 14px !important;
      width: 14px !important;
      height: 14px !important;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 8px;
    flex-shrink: 0;

    &-text {
      font-size: 12px;
      line-height: 20px;
      letter-spacing: -0.154px;
      color: #333333;
      text-transform: uppercase;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f5f5f5;
    border-radius: 3px;

    &-actions {
      flex-shrink: 0;

      &-button {
        width: 20px !important;
        height: 20px !important;

        &-icon {
          font-size: 14px !important;
          line-height: 14px !important;
          width: 14px !important;
          height: 14px !important;
        }
      }
    }

    &-button {
      //background-color: rgba(92, 144, 2, 0.1);
      background-color: map-get($map: $primary-pallete, $key: 50);
      white-space: normal !important;
      text-align: left !important;
      font-size: 14px !important;
      line-height: 20px !important;
      padding: 4px 11px !important;
      letter-spacing: -0.154px !important;
      max-width: 100%;
    }

    &s {
      overflow: auto;
    }

    &-field {
      font-size: 12px;
      line-height: 20px;
      letter-spacing: -0.154px;
      color: #333333;
      flex-shrink: 1;

      .mat-form-field-wrapper {
        margin: 0;
        padding-bottom: 0;
      }

      .mat-form-field-infix {
        padding: 0.3em 0 0.3em 0;
        width: auto;
      }

      .mat-form-field-outline {
        background-color: white;
      }
    }
  }

  &__item + &__item {
    margin-top: 6px;
  }

  &__item + &__item-field {
    margin-top: 6px;
  }

  &__button {
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: -0.154px;
    text-transform: uppercase;
    color: #333333;
    opacity: 0.7;
    transition: opacity 0.2s linear;
    cursor: pointer;

    &s {
      margin-top: auto;
      flex-shrink: 0;
      padding-top: 8px;
      min-height: 20px;
    }
  }

  &__button:hover {
    opacity: 0.4;
  }
}
