@import "./history-offers/history-offers.component";

.atp-dialog-container {

  .management-offers {
    //width: calc(100vw - 100px) !important;

    &__title {
      padding-top: 0;

      &-item {
        display: block;
      }
    }

    &__technical-items {
      //margin-left: 66px;
    }

    .atp-dynamic-form__actions {
      //padding-right: 32px;
    }

    .atp-dynamic-form__container{
      max-height: 100%;
      overflow-y: auto;
    }
  }
  .ag-root.ag-layout-normal, .ag-root.ag-layout-auto-height{
    overflow: inherit;
  }

  .ag-theme-material .ag-header{
    position: sticky;
    top:-28px;
    z-index: 1;
  }

  .ag-root-wrapper{
    overflow: inherit;
  }
}

.ag-root.ag-layout-normal, .ag-root.ag-layout-auto-height{
  overflow: inherit;
}

.ag-theme-material .ag-header{
  position: sticky;
  top:-24px;
  z-index: 1;
}

.ag-root-wrapper{
  overflow: inherit;
}

.management-offers {
  display: block;
  position: relative;

  &__title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding-top: 24px;
    padding-bottom: 24px;

    &-item {
      display: none;
    }

    .lot-time {
      margin-left: auto;
    }
  }

  &__technical-items {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    margin-right: 8px;

    .lot-technical-item + .lot-technical-item {
      margin-left: 24px;
    }
  }

  &__ag-grid {
    &-container {
      width: calc(100% - 6px);
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      padding: 8px;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.24);
      background-color: white;
      border-radius: 3px;
      margin: 3px 3px 6px;

      //изменил
      height: 100%;

    }

    &_content {
      height: 100%;
      flex-grow: 1;
    }

    &_footer {
      flex-grow: 0;
    }
  }

  &__excel-list {
    margin-left: auto !important;
  }

  .atp-dynamic-form__actions {
    padding-right: 0;
  }
}

.management-offers__ag-grid_footer.ag-theme-material {
  .ag-row {
    min-height: auto;
  }

  .ag-cell {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.154px;
    color: $custom-dark-primary-text;
    padding: 3.5px 12px;
    text-align: right;
  }

  .ag-pinned-left-cols-container {
    text-align: right;
  }
}

.management-offers__table-body-column_best {
  background-color: rgba(82, 143, 0, 0.1) !important;
}

.management-offers__table-body-column_blocked {
  background-color: #f79a9a;
}

.participants-profile {
  position: relative;
  display: block;
  height: 100%;
}

// .management-offers-popup {

// }
