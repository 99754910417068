.atp-dialog-container {
  .history-offers {
    .atp-dynamic-form__title {
      display: block;
    }

    &__users {
      padding-top: 0;
    }
  }
}

.price-history{
  .atp-dynamic-form__form {
    height: inherit;
  }
}

.atp-dynamic-form__form {
  display: flex;
  flex-direction: column;
  //height: inherit;

  //изменил
  // height: 100%;
  flex: 1 1 auto;
}

.history-offers {
  display: block;
  position: relative;

  .atp-dynamic-form__title {
    display: none;
  }

  &__ag-grid {
    &-container {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      padding: 8px;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.24);
      background-color: white;
      border-radius: 3px;
      margin: 3px 3px 6px;
    }

    &_content {
      height: 100%;
      flex-grow: 1;
    }

    &_footer {
      flex-grow: 0;
    }
  }

  &__participants {
    display: flex;
    flex-wrap: wrap;
    margin-left: -12px;

    &-checkbox {
      margin-top: 12px;
      margin-left: 12px;
    }

    &-all {
      margin-bottom: 8px;
    }
  }

  &__users {
    padding: 12px;
  }
}
