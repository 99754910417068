.atp-filters-panel {
  position: relative;
  display: block;
  flex-grow: 1;
  max-width: 838px;

  &__search {
    display: flex;
    background: #ffffff;
    border: 1px solid #d3d9de;
    border-radius: 3px;
    padding: 3px 3px 3px 18px;

    &-chip {
      &-text {
        overflow: hidden;
        max-width: 130px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -moz-box;
        -moz-box-orient: vertical;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        line-clamp: 1;
        box-orient: vertical;
        white-space: normal;
        height: 20px;
      }

      &s {
        flex-shrink: 0;
        display: flex;
        padding: 0 16px 0 8px;
      }
    }

    &-chip + &-chip {
      margin-left: 8px !important;
    }

    &-icon {
      &-container {
        display: flex;
        align-items: center;
      }
    }

    &-input {
      height: 100%;
      width: 100%;
      font-size: 16px;
      line-height: 20px;
      color: #333333;

      &-container {
        display: flex;
        flex-grow: 1;
        padding: 0 8px;
        margin-bottom: -2px;
      }

      &-clear {
        display: flex;
        align-items: center;
        font-size: 16px;
        line-height: 20px;
        color: #bdbdbd;
        margin-top: -4px;
        cursor: pointer;
        flex-shrink: 0;
        padding: 0 26px;
        transition: opacity 0.2s linear;
      }

      &-clear:hover {
        opacity: 0.5;
      }
    }

    &-input::placeholder {
      color: #bdbdbd;
    }

    &-button {
      flex-shrink: 0;
      padding: 0 11px !important;
      // background: rgba(82, 143, 0, 0.1);
      background: map-get($map: $primary-pallete, $key: 50);

      .mat-icon {
        margin-top: -4px;
        margin-right: 4px !important;
      }
    }
  }

  &__filters {
    position: relative;
    top: 8px;
    z-index: 10001;
    display: flex;
    align-self: flex-start;
    max-width: 700px;
    width: 100%;
    max-height: calc(100% - 16px);
    z-index: 10000;
    background-color: #f5f5f5;
    border: 1px solid #d3d9de;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.24);
    border-radius: 3px;
    padding: 10px;

    &-close-container {
      position: fixed;
      z-index: 10000;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }

    &-body {
      display: flex;
      flex-direction: column;
      background-color: white;
      flex-grow: 1;
      border-radius: 3px;
      padding: 20px 16px;
    }

    &-actions {
      &-line {
        display: flex;
        justify-content: flex-end;

        &_fields {
          justify-content: space-between;
          padding-bottom: 26px;

          .atp-filters-panel__filters-actions-button {
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: -0.154px;
            cursor: pointer;
            transition: opacity 0.2s linear;

            &_add {
              //color: #528f00;
              color: map-get($map: $primary-pallete, $key: 500);
            }

            &_clear {
              color: #bdbdbd;
              padding-bottom: 4px;
              border-bottom: 1px dashed #bdbdbd;
            }
          }

          .atp-filters-panel__filters-actions-button:hover {
            opacity: 0.5;
          }
        }
      }

      &-button + &-button {
        margin-left: 8px;
      }
    }
  }

  &__add-fields {
    &-close-container {
      position: fixed;
      z-index: 10000;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }

    &-popup {
      position: relative;
      z-index: 10001;
      background-color: white;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.24);
      padding: 4px 20px 20px 0;

      &-container {
        display: grid;
        grid-auto-rows: auto;
      }

      .mat-checkbox {
        margin-top: 16px;
        margin-left: 20px;
      }
    }
  }
}

@import "./atp-filters-panel-presets.component";
@import "./atp-filters-panel-controls.component";
